import PageAbout from '../components/PageAbout'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageAbout {
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default PageAbout
